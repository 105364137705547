// const API_URL = 'http://localhost:3001/api/content/';
// const API_URL = 'https://api.anchorpoint.world/content/v1';
// console.log(process.env.NODE_ENV);
const API_URL = process.env.REACT_APP_API_URL;
const doApiCall = true;

export const fetchContentData = async () => {
  try {
    // const response = await fetch(`${API_URL}`);
    // const response = await fetch(`${API_URL}/items`);
    const url = doApiCall ? `${API_URL}/items` : 'content.json';
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Failed to fetch content data');
    }
    const data = await response.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.error('Error fetching content data:', error);
    return [];
  }
};

export const itemsData =  await fetchContentData();

export const fetchCss = async (item) => {
  try {
    const response = await fetch(`${API_URL}/css/${item}`);
    if (!response.ok) {
      throw new Error('Failed to fetch item data');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching item data:', error);
    return null;
  }
};

export const fetchItemData = async (item) => {
  // try {
  //   const response = await fetch(`${API_URL}/items/${item}`);
  //   if (!response.ok) {
  //     throw new Error('Failed to fetch item data');
  //   }
  //   const data = await response.json();
  //   return data;
  // } catch (error) {
  //   console.error('Error fetching item data:', error);
  //   return null;
  // }
  // return itemsData.find(itemData => itemData.id === item);
  return itemsData[1]?.[item];
};

// Toggle the reCAPTCHA badge on the contact form.
// https://stackoverflow.com/a/66683299/10372580
export const toggleRecaptchaBadge = (show) => {
  const badge = document.getElementsByClassName('grecaptcha-badge')[0];
  if (badge && badge instanceof HTMLElement) {
    badge.style.visibility = show ? 'visible' : 'hidden';
  }
};

export const gRecaptchaSiteKey = "6LecQf4pAAAAAPA8LD-NVj78S595JjnvAsrVLlTw";

// load the reCAPTCHA script.
// https://stackoverflow.com/a/65899778/10372580
export const loadRecaptcha = () => {
  const script = document.createElement("script");
  script.src = `https://www.google.com/recaptcha/api.js?render=${gRecaptchaSiteKey}`;
  script.id = 'recaptcha-script';
  script.async = true;
  document.body.appendChild(script);

  return script
}

// remove the reCAPTCHA script and badge.
export const removeRecaptcha = () => {
  const script = document.getElementById('recaptcha-script');
  if (script) {
      script.remove();
  }

  const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
  if (recaptchaElems.length) {
      recaptchaElems[0].remove();
  }
}

import React, { useEffect, useState } from "react";
import Card from "./Card";
// import { fetchContentData } from '../api';
import { itemsData } from "../Common";

// Import des images et des logos
import logoPortPulse from "../assets/logos/logo_portpulse-blue.png";
import logoPortNexus from "../assets/logos/logo_portnexus-blue.png";
import logoPortAcademy from "../assets/logos/logo_portacademy-blue.png";
import logoPortCloud from "../assets/logos/logo_portcloud-blue.png";
import logoPortTalent from "../assets/logos/logo_porttalent-blue.png";
import logoPortVentures from "../assets/logos/logo_portventures-blue.png";
import logoPortAnalytics from "../assets/logos/logo_portanalytics-blue.png";
import logoPortSurveys from "../assets/logos/logo_portsurveys-blue.png";
import logoPortTenders from "../assets/logos/logo_porttenders-blue.png";
import logoPortEvents from "../assets/logos/logo_portevents-blue.png";
import logoPortGames from "../assets/logos/logo_portgames-blue.png";
import logoPortMaps from "../assets/logos/logo_portmaps-blue.png";

import imgPulse from "../assets/images/card_pulse.webp";
import imgNexus from "../assets/images/card_nexus.webp";
import imgAcademy from "../assets/images/card_academy.webp";
import imgCloud from "../assets/images/card_cloud.webp";
import imgTalent from "../assets/images/card_talent.webp";
import imgVentures from "../assets/images/card_ventures.webp";
import imgAnalytics from "../assets/images/card_analytics.webp";
import imgSurveys from "../assets/images/card_surveys.webp";
import imgTenders from "../assets/images/card_tenders.webp";
import imgEvents from "../assets/images/card_events.webp";
import imgGames from "../assets/images/card_games.webp";
import imgMaps from "../assets/images/card_maps.webp";

// Mappage des logos et des images
const logoMap = {
  pulse: logoPortPulse,
  nexus: logoPortNexus,
  academy: logoPortAcademy,
  cloud: logoPortCloud,
  talent: logoPortTalent,
  ventures: logoPortVentures,
  analytics: logoPortAnalytics,
  surveys: logoPortSurveys,
  tenders: logoPortTenders,
  events: logoPortEvents,
  games: logoPortGames,
  maps: logoPortMaps,
};

const imageMap = {
  pulse: imgPulse,
  nexus: imgNexus,
  academy: imgAcademy,
  cloud: imgCloud,
  talent: imgTalent,
  ventures: imgVentures,
  analytics: imgAnalytics,
  surveys: imgSurveys,
  tenders: imgTenders,
  events: imgEvents,
  games: imgGames,
  maps: imgMaps,
};

const Cards = () => {
  const [cardsData, setCardsData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      //   const data = await fetchContentData();
      //   setCardsData(data[0].map(({ name, title, description, content, link, css }) => ({
      //     name,
      //     imgSrc: imageMap[name],
      //     logoSrc: logoMap[name],
      //     title,
      //     description,
      //     content,
      //     link,
      //     css,
      //   })));
      // };
      const data = itemsData[0].map(
        ({ name, title, description, content, link, css }) => ({
          name,
          imgSrc: imageMap[name],
          logoSrc: logoMap[name],
          title,
          description,
          content,
          link,
          css,
        })
      );

      // Sort data so that cards with links starting with "http" come first
      data.sort((a, b) => {
        const isExternalA = a.link.startsWith("http");
        const isExternalB = b.link.startsWith("http");
        return isExternalA === isExternalB ? 0 : isExternalA ? -1 : 1;
      });

      setCardsData(data);
    };
    getData();
  }, []);

  return (
    <section id="cards" className="lg:mx-28 mb-16 p-8 animate-fadeInUp">
      <h3 className="text-3xl font-bold text-gray-50 mb-6 hidden">
        Our offer of services
      </h3>
      <div
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4"
        id="cards-container"
      >
        {cardsData.map((card, index) => (
          <Card key={index} card={card} />
        ))}
      </div>
    </section>
  );
};

export default Cards;
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Header from './components/Header';
import Footer from './components/Footer';
import CookieConsent from './components/CookieConsent';
import Home from './pages/Home';
import LegalMentions from './pages/LegalMentions';
import TermsOfSales from './pages/TermsOfSales';
import WaitingList from './pages/WaitingList';
import ScrollToTop from './components/scrollToTop';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <div className="App bg-customBlue">
          <Header />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/index.html" element={<Home />} />
            <Route path="/legal-mentions.html" element={<LegalMentions />} />
            <Route path="/terms-of-sales.html" element={<TermsOfSales />} />
            <Route path="/waiting-list.html" element={<WaitingList />} />
          </Routes>
          <Footer />
          <CookieConsent />
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

/*
<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-XXXXX"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-XXXXX');
</script>
*/

const CookieConsent = () => {
  const [showConsent, setShowConsent] = useState(false);

  useEffect(() => {
    // Load gtag.js script
    const loadGtag = () => {
      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtag/js?id=G-XXXXX`;
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        window.gtag = gtag;
        window.gtag('js', new Date());

        // Set default consent state to denied
        window.gtag('consent', 'default', {
          'ad_storage': 'denied',
          'ad_user_data': 'denied',
          'ad_personalization': 'denied',
          'analytics_storage': 'denied',
          'wait_for_update': 500
        });

        // TODO: Consider only using a single cookie that is set to true/false
        // TODO: we only need either cookiesAccepted or cookiesRefused not both
        // TODO: It will be easier and tighter.
        // Proposed name for the replacement cookie: cookiesConsent: true or false 
        if (Cookies.get('cookiesAccepted')) {
          updateConsent('granted');
        } else if (Cookies.get('cookiesRefused')) {
          updateConsent('denied');
        }
      };
    };

    // if (!Cookies.get('cookiesAccepted') && !Cookies.get('cookiesRefused')) {
    //   setShowConsent(true);
    // }
    const cookiesConsent = Cookies.get('cookiesConsent');
    if (cookiesConsent === undefined || cookiesConsent === null) {
      // Clean up any existing cookies
      const domain = window.location.hostname.split('.').reverse().splice(0,2).reverse().join('.');
      for (const cookie in Cookies.get()) {
        Cookies.remove(cookie, {path: '/', domain: domain});
        Cookies.remove(cookie, {path: '/', domain: window.location.hostname});
        Cookies.remove(cookie);
      }
      setShowConsent(true);
    }

    // loadGtag();
  }, []);

  // TODO: Review the need for ad_storage, ad_personalization and ad_user_data
  // https://developers.google.com/tag-platform/security/guides/consent?consentmode=advanced
  const updateConsent = (consentState) => {
    window.gtag('consent', 'update', {
      // 'ad_storage': consentState,
      // 'ad_personalization': consentState,
      // 'ad_user_data': consentState,
      'analytics_storage': consentState
    });

    if (consentState === 'granted') {
      window.gtag('js', new Date());
      window.gtag('config', 'G-XXXXX', { 'anonymize_ip': true });
    }
  };

  const handleAcceptCookies = () => {
    Cookies.set('cookiesConsent', 'true', { expires: 365 });
    Cookies.set('cookiesAccepted', 'true', { expires: 365 });
    setShowConsent(false);
    // updateConsent('granted');
  };

  const handleRefuseCookies = () => {
    Cookies.set('cookiesConsent', 'false', { expires: 365 });
    Cookies.set('cookiesRefused', 'true', { expires: 365 });
    setShowConsent(false);
    // updateConsent('denied');
  };

  if (!showConsent) return null;

  return (
    <div id="cookieConsent" className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full text-center">
        <h3 className="text-xl font-semibold mb-4">Cookie Consent</h3>
        <p className="mb-4">
          We use essential cookies for site functionality and analytics cookies to help us improve user experience. 
          Learn more in our&nbsp;
          <a href="legal-mentions.html#cookies-policy" className="text-customBlue underline">Cookies Policy</a>.
        </p>
        <div className="flex justify-center space-x-4">
          <button 
            id="acceptCookies" 
            className="bg-customBlue text-white py-2 px-4 rounded hover:bg-customRed"
            onClick={handleAcceptCookies}
          >
            Accept All
          </button>
          <button 
            id="refuseCookies" 
            className="bg-gray-300 text-gray-700 py-2 px-4 rounded hover:bg-gray-400"
            onClick={handleRefuseCookies}
          >
            Refuse Analytics
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;

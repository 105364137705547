import React, { useEffect } from 'react';
import Hero from '../components/Hero'
import { removeRecaptcha, toggleRecaptchaBadge } from "../Common";

const LegalMentions = () => {
  useEffect(() => {
    toggleRecaptchaBadge(false);
    removeRecaptcha();
  }, []);

  return (
    <div className="bg-customGray">
      <main>
        <Hero title="AnchorPoint" subtitle="Legal Mentions" />
        {/* Start Section Legal mentions */}
        <section id="legal-mentions" className="mb-16 p-8 animate-fadeInUp scroll-offset">
          <div className="container mx-auto">
            <p className="text-md md:text-lg text-customBlue mb-4">
              The Site is published by MILLE407 SAS, with a capital of €30,000.00, registered at the RCS of La Rochelle under the number SIRET 83219420300010 (NAF code 7022Z) with its headquarters located at 76 B avenue Raymond Poincaré, 17000 La Rochelle, France (hereinafter “MILLE407”).
            </p>
            <p className="text-md md:text-lg text-customBlue mb-4">
              Phone number: +33 (0) 6 33 65 37 25<br />
              Email address: contact@anchorpoint.world<br />
              VAT number: FR16832194203
            </p>
            <p className="text-md md:text-lg text-customBlue mb-4">
              The publication director is Mr. Matthieu DESIDERIO.<br />
              Photo credits: Pixabay; MILLE407 SAS; Maxime Pagnoux.
            </p>
            <p className="text-md md:text-lg text-customBlue mb-4">
              The Site is hosted by O2Switch, whose headquarters is located at 222-224 Boulevard Gustave Flaubert, 63000 Clermont-Ferrand, France.
            </p>
            <p className="text-md md:text-lg text-customBlue mb-4">
              Acceptance of the General Terms of Use: These GTU govern the use of the Site. They apply to you as soon as you access the Site, from the homepage or any other page. Connection and navigation on the Site by the User implies full and unreserved acceptance of these GTU. The User who does not wish to be bound by these GTU must immediately leave the Site and cease its use. MILLE407 reserves the right to modify and update the GTU at any time. Each new version of the general terms of use takes effect from the date it is posted on the Site. You are therefore invited to regularly consult the general terms of use in force at the time of your access to the Site, from the homepage or any other page.
            </p>
            <p className="text-md md:text-lg text-customBlue mb-4">
              Access to the Site: The Site is accessible free of charge from anywhere to any User with Internet access. All costs borne by the User to access the service (computer hardware, software, Internet connection, etc.) are at their expense.
            </p>
            <p className="text-md md:text-lg text-customBlue mb-4">
              Data Collection and Management: The Site ensures the User a collection and processing of personal information with respect for privacy in accordance with law n°78-17 of January 6, 1978, relating to data processing, files, and freedoms, and the provisions of the General Data Protection Regulation (GDPR: n° 2016-679) ("hereinafter GDPR"). In accordance with the Data Protection Act of January 6, 1978, and the provisions of the GDPR, the User has the right to access, rectify, delete, and oppose their personal data. The User exercises this right:
            </p>
            <ul className="list-disc list-inside text-md md:text-lg text-customBlue mb-4">
              <li>By email at contact@anchorpoint.world</li>
              <li>By postal mail at 76 B avenue Raymond Poincaré, 17000 La Rochelle, France</li>
              <li>Via a contact form</li>
            </ul>
            <p className="text-md md:text-lg text-customBlue mb-4">
              Intellectual Property: The Site and its content are protected by the Intellectual Property Code and in particular by copyright, which are the property of MILLE407 or third parties. The User must obtain the prior authorization of MILLE407 for any reproduction, publication, or copy of the various contents. The User agrees to use the contents of the Site in a strictly private context, any use for commercial and advertising purposes is strictly prohibited. Any total or partial representation of the Site by any means whatsoever, without the express authorization of MILLE407, would constitute an infringement punishable by article L 335-2 and following of the Intellectual Property Code. It is recalled in accordance with article L122-5 of the Intellectual Property Code that the User who reproduces, copies, or publishes protected content must cite the author and their source.
            </p>
            <p className="text-md md:text-lg text-customBlue mb-4">
              Responsibility: The sources of the information disseminated on the Site are deemed reliable but MILLE407 does not guarantee that it is free of defects, errors, or omissions. The information provided is presented for indicative and general purposes without contractual value. Despite regular updates, MILLE407 cannot be held responsible for changes in administrative and legal provisions occurring after publication. Similarly, MILLE407 cannot be held responsible for the use and interpretation of the information contained on the Site. MILLE407 cannot be held responsible for any viruses that may infect the User's computer or any computer equipment, following use, access, or downloading from this Site. MILLE407 cannot be held liable in case of force majeure or the unforeseeable and insurmountable act of a third party.
            </p>
            <p className="text-md md:text-lg text-customBlue mb-4">
              Hypertext Links: The Site may contain links to third-party sites. These links are provided solely for convenience, and the inclusion of these links does not imply any control of the elements of the sites to which these links give access nor any approval, warranty, or endorsement of these elements. Access to these sites is the sole responsibility of the User.
            </p>
            <p id="cookies-policy" className="text-md md:text-lg text-customBlue mb-4">
              Cookies Policy: The User is informed that during their visits to the Site, a cookie may be automatically installed on their browser software. Cookies are small files stored temporarily on the User's computer hard drive by the browser and are necessary for the use of the Site. Cookies do not contain personal information and cannot be used to identify anyone. A cookie contains a unique, randomly generated identifier and is therefore anonymous. Some cookies expire at the end of the User's visit, others remain. The information contained in cookies is used to improve the Site. By browsing the Site, the User accepts them. The User must, however, give their consent to the use of certain cookies. Failing acceptance, the User is informed that certain features or pages may be refused. The User can disable these cookies through the settings in their browser software. 
              <br/>
              <i>Essential Cookies</i>:
              <br/>
              Cookie Consent Cookie: cookiesAccepted, cookiesRefused
              <br/>
              Purpose: These cookies store the user's preferences regarding cookie consent. When a user accepts or refuses cookies, these cookies remember the user's choice so that they are not prompted again on subsequent visits.
              <br/>
              Duration: 365 days.
              <br/>
              <i>Analytics Cookies: Google Analytics</i>
              <br/>
              Name: _ga, _gid, _gat
              <br/>
              Purpose: These cookies are used to collect information about how visitors use the site. This information is used to compile reports and help us improve the site. The cookies collect information in an anonymous form, including the number of visitors to the site, where visitors have come to the site from, and the pages they visited.
              <br/>
              Duration:
              <br/>
              _ga: 2 years
              <br/>
              _gid: 24 hours
              <br/>
              _gat: 1 minute
              <br/>
              We use Google Analytics to analyze the use of our website. Google Analytics generates statistical data about the use of the website by means of cookies. (<a href="https://commission.europa.eu/cookies-policy_en" className="text-gray-600">https://commission.europa.eu/cookies-policy_en</a>) 
            </p>
            <p className="text-md md:text-lg text-customBlue mb-4">
              Modifications: MILLE407 may modify the elements published on the Site or any information included therein at any time and without prior notice.
            </p>
            <p className="text-md md:text-lg text-customBlue mb-4">
              Applicable Law and Jurisdiction: The Site and these GTU are subject to French law. In the absence of an amicable resolution of a dispute arising between the parties, only the French courts will have jurisdiction to hear it.
            </p>
            <p className="text-md md:text-lg text-customBlue mb-4">
              Definitions:
            </p>
            <ul className="list-disc list-inside text-md md:text-lg text-customBlue mb-4">
              <li>Client: any professional or capable natural person within the meaning of articles 1123 and following of the Civil Code, or legal person, who visits the Site subject to these general conditions.</li>
              <li>Content: All the elements constituting the information present on the Site, in particular texts, images, sounds, videos.</li>
              <li>User: Internet user connecting, using the aforementioned Site.</li>
              <li>Personal information: "Information which allows, in any form whatsoever, directly or indirectly, the identification of the natural persons to whom it applies" (article 4 of law n° 78-17 of January 6, 1978).</li>
              <li>The terms "personal data", "data subject", "processor" and "sensitive data" have the meaning defined by the General Data Protection Regulation (GDPR: n° 2016-679).</li>
            </ul>
          </div>
        </section>
        {/* End Section Legal mentions */}
      </main>
    </div>
  );
};

export default LegalMentions;

import React, { useEffect }  from "react";
import { Helmet } from 'react-helmet-async';
import Hero from '../components/Hero';
import Carousel from '../components/Carousel';
import Cards from '../components/Cards';
import About from '../components/About';
import Contact from '../components/Contact';
import { removeRecaptcha, toggleRecaptchaBadge } from "../Common";

const Home = () => {
  useEffect(() => {
    toggleRecaptchaBadge(false);
    removeRecaptcha();
  }, []);

  return (
    <div>
       <Helmet>
        <title>AnchorPoint - The Single Source of Truth for the Ports & Maritime Industry</title>
        <meta name="description" content="AnchorPoint is a web-based platform for the Ports & Maritime Industry Ecosystem that will be an essential central resource for all P&MI Professionals at all levels across the globe." />
        <meta name="keywords" content="ports, terminals, industry news, AI, personalized software, AnchorPoint" />
        <meta name="author" content="AnchorPoint" />
        <link rel="canonical" href="https://anchorpoint.world/" />
        <meta property="og:title" content="AnchorPoint - The Single Source of Truth for the Ports & Maritime Industry" />
        <meta property="og:description" content="AnchorPoint is a web-based platform for the Ports & Maritime Industry Ecosystem that will be an essential central resource for all P&MI Professionals at all levels across the globe." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://anchorpoint.world/" />
        <meta property="og:image" content="assets/images/anchorpoint_metacard.webp" />
        <meta property="og:site_name" content="AnchorPoint" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="AnchorPoint - The Single Source of Truth for the Ports & Maritime Industry" />
        <meta name="twitter:description" content="AnchorPoint is a web-based platform for the Ports & Maritime Industry Ecosystem that will be an essential central resource for all P&MI Professionals at all levels across the globe." />
        <meta name="twitter:image" content="assets/images/anchorpoint_metacard.webp" />
        <meta name="twitter:site" content="@AnchorPoint" />
        <meta name="twitter:creator" content="@AnchorPoint" />
        <link rel="apple-touch-icon" sizes="180x180" href="assets/icons/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="assets/icons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="assets/icons/favicon-16x16.png" />
        <link rel="manifest" href="anchorpoint.webmanifest" />
        <link rel="mask-icon" href="assets/icons/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#2d89ef" />
        <meta name="theme-color" content="#0E2841" />
        <meta itemprop="name" content="AnchorPoint - The Single Source of Truth for the Ports & Maritime Industry" />
        <meta itemprop="description" content="AnchorPoint is a web-based platform for the Ports & Maritime Industry Ecosystem that will be an essential central resource for all P&MI Professionals at all levels across the globe." />
        <meta itemprop="image" content="assets/images/anchorpoint_metacard.webp" />
        <meta name="robots" content="index, nofollow" />
      </Helmet>
      <main>
        <Hero title="AnchorPoint" subtitle="The Single Source of Truth for the Ports & Maritime Industry" />
        <Carousel/>
        <Cards />
        <About />
        <Contact />
      </main>
    </div>
  );
};

export default Home;

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import ContactForm from '../components/ContactForm';
import { fetchItemData } from '../Common';

const WaitingList = () => {
  // Read item reference from location.state or module URL params 
  // to support waiting-list.html calls from external links
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const item = location.state?.item ? location.state.item : urlParams.get('module');
  const [itemData, setItemData] = useState(null);

  useEffect(() => {
    const getItemData = async () => {
      if (item) {
        // const data = await fetchContentData();
        // const selectedItem = data.find((dataItem) => dataItem.item === item);
        const selectedItem = await fetchItemData(item);
        setItemData(selectedItem);
      }
    };

    getItemData();
  }, [item]);

  return (
    <div>
      <Helmet>
        <title>{`Waiting List - ${item}`}</title>
        <meta name="description" content={`Join the waiting list for ${item}`} />
      </Helmet>
      <main className="bg-customGray">
        {/* Start Hero Section */}
        <section id="hero" className="relative w-full h-96 bg-cover bg-center -mt-16 hero animate-fadeInUp">
          <div className="absolute bottom-0 left-0 p-8 bg-customBlue bg-opacity-50 text-gray-50 rounded-tr-lg">
            <h1 className="text-center md:text-left text-4xl font-bold">AnchorPoint</h1>
            <h2 className="text-center md:text-left text-xl md:text-2xl">Join the Waiting List, Get Exclusive Insights</h2>
          </div>
        </section>
        {/* End Hero Section */}
        <div className="container mx-0 lg:mx-4 p-4 flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4 animate-fadeInUp">
          {/* Start Section PortXXX Description */}
          <section className="w-full lg:w-2/3 p-4 pt-4 rounded-lg shadow-md flex flex-col justify-center lg:justify-start">
            <div id="port-description" className="text-md md:text-lg pt-4 text-gray-800 space-y-4">
              {itemData?.content ? (
                <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(itemData.content) }} />
              ) : (
                <div>
                  <p className="mt-4"><span className="font-bold">AnchorPoint</span> is a web-based platform for the ports & maritime industry ecosystem that will be an essential central resource for all P&MI professionals at all levels across the globe.</p>
                  <p className="mt-4">This AI-powered platform will provide easily accessible knowledge, analytics and benchmarking, training, a digital marketplace, human resources, a tendering system for equipment and projects, access to funding incubation and investors, as well as a collaborative space for innovation and connectivity.</p>
                  <p className="mt-4 font-bold">AnchorPoint will accelerate innovation, promote sustainability, facilitate connectivity, and drive efficiency across the ports & maritime industry.</p>
              </div>
              )}
            </div>
          </section>
          {/* End Section PortXXX Description */}
          {/* Start Section Contact Form */}
          <section id="contact" className="w-full lg:w-1/3 mb-16 p2 lg:p-8 scroll-offset">
            <ContactForm />
          </section>
          {/* End Section Contact Form */}
        </div>
      </main>
    </div>
  );
};

export default WaitingList;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navigation from './Navigation';
import logo from '../assets/logos/logo-anchorpoint-white.webp';

const Header = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isDark, setIsDark] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsDark(true);
      } else {
        setIsDark(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header id="header" className={`sticky top-0 z-50 glass p-2 shadow-md ${isDark ? 'dark' : ''}`}>
      <div className="max-w-[1600px] mx-14 flex justify-between items-center">
        <div className="w-32 md:w-48 lg:w-72">
          <Link to="/">
            <img src={logo} alt="Logo AnchorPoint - Home" />
          </Link>
        </div>
        <Navigation isMobile={false} />
        <button
          title="menu-toggle"
          id="menu-toggle"
          onClick={toggleMobileMenu}
          className="block md:hidden text-gray-50 hover:text-gray-200 focus:outline-none"
        >
          <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
          </svg>
        </button>
      </div>
      {isMobileMenuOpen && (
        <div id="mobile-menu" className="md:hidden">
          <Navigation isMobile={true} />
        </div>
      )}
    </header>
  );
};

export default Header;

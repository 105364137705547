import React from 'react';
import { Link } from 'react-router-dom';
import Who from './Who';

const Contact = () => {
  return (
    <section id="contact" className="mb-16 p-8 animate-fadeInUp scroll-offset">
      <div className="container mx-auto text-center">
        <h3 className="text-3xl font-bold text-gray-50 mb-6">Be the first to know if you're eligible!</h3>
        <p className="text-lg md:text-xl text-gray-50 mb-4">
          <Link className="hover:text-gray-400" to="/waiting-list.html">Sign up for our waiting list to get exclusive content tailored just for you</Link>
        </p>
        <Who />
        <p className="text-center text-lg md:text-xl text-gray-50 mb-4 mt-6">
          <Link className="hover:text-gray-400" to="/waiting-list.html">Join AnchorPoint and accelerate your success in the Port & Maritime Industry!</Link>
        </p>
      </div>
      <p className="text-lg text-center md:text-xl text-gray-50 mt-10 mb-4">For any inquiries, you may also reach out to us at:</p>
      <p id="contact_address" className="text-lg text-center md:text-2xl font-semibold text-gray-50">info@<b>@anchorpoint.</b>anchorpoint.world</p>
    </section>
  );
};

export default Contact;

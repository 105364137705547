import React, { useEffect, useRef, useState } from 'react';
// import { fetchContentData } from '../api';
import { itemsData } from '../Common';
import { Link } from 'react-router-dom';

// Import des logos
import logoPortPulse from '../assets/logos/round_pulse.png';
import logoPortNexus from '../assets/logos/round_nexus.png';
import logoPortAcademy from '../assets/logos/round_academy.png';
import logoPortCloud from '../assets/logos/round_cloud.png';
import logoPortTalent from '../assets/logos/round_talent.png';
import logoPortVentures from '../assets/logos/round_ventures.png';
import logoPortAnalytics from '../assets/logos/round_analytics.png';
import logoPortSurveys from '../assets/logos/round_surveys.png';
import logoPortTenders from '../assets/logos/round_tenders.png';
import logoPortEvents from '../assets/logos/round_events.png';
import logoPortGames from '../assets/logos/round_games.png';
import logoPortMaps from '../assets/logos/round_maps.png';

// Mappage des logos
const logoMap = {
  pulse: logoPortPulse,
  nexus: logoPortNexus,
  academy: logoPortAcademy,
  cloud: logoPortCloud,
  talent: logoPortTalent,
  ventures: logoPortVentures,
  analytics: logoPortAnalytics,
  surveys: logoPortSurveys,
  tenders: logoPortTenders,
  events: logoPortEvents,
  games: logoPortGames,
  maps: logoPortMaps,
};

const Marquee = () => {
  const trackRef = useRef(null);
  const [marqueeItems, setMarqueeItems] = useState([]);

  useEffect(() => {
    const getData = async () => {
      // const data = await fetchContentData();
      // setMarqueeItems(data[0].map(({ name, title, link }) => ({
      //   src: logoMap[name],
      //   alt: title,
      //   item: name,
      //   link,
      //   state: name,
      // })));
      setMarqueeItems(itemsData[0].map(({ name, title, link }) => ({
        src: logoMap[name],
        alt: title,
        item: name,
        link,
        state: name,
      })));
    };
    getData();
  }, []);

  useEffect(() => {
    const track = trackRef.current;
    if (!track) return;

    // Duplicate items to make the marquee seamless
    const clone = track.cloneNode(true);
    track.appendChild(clone);

    let step = 0.5;
    let position = 0;
    const width = track.scrollWidth / 2;

    const move = () => {
      position = (position + step) % width;
      track.style.transform = `translateX(-${position}px)`;
      requestAnimationFrame(move);
    };

    track.addEventListener('mouseenter', () => (step = 0));
    track.addEventListener('mouseleave', () => (step = 0.5));

    move();
  }, [marqueeItems]);

  return (
    <div className="marquee-wrapper relative overflow-hidden">
      <div className="marquee-container relative flex blur-border">
        <div className="marquee-track flex" ref={trackRef}>
          {marqueeItems.map((item, index) => (
            <div className="marquee-item p-2" key={index}>
              <Link to={item.link} state={{item: item.state}}>
                <img src={item.src} alt={item.alt} className="w-24 h-auto" />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Marquee;

import React from 'react';

const KeyFeatures = () => {
  const features = [
    { icon: 'fa-brain', text: 'AI-Powered Knowledge' },
    { icon: 'fa-chart-line', text: 'Analytics & Benchmarking' },
    { icon: 'fa-chalkboard-teacher', text: 'Training Programs' },
    { icon: 'fa-shopping-cart', text: 'Digital Marketplace' },
    { icon: 'fa-users', text: 'Human Resources' },
    { icon: 'fa-file-contract', text: 'Tendering System' },
    { icon: 'fa-hand-holding-usd', text: 'Funding & Investment Access' },
    { icon: 'fa-people-arrows', text: 'Collaborative Space' },
  ];

  return (
    <div className="bg-gray-50 rounded-lg p-4 lg:p-6 shadow-md text-center mb-6">
      <h4 className="text-2xl font-semibold text-customBlue mb-4">Key Features</h4>
      <ul className="text-md text-gray-800 space-y-2 grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-2">
        {features.map((feature, index) => (
          <li key={index} className="flex justify-center">
            <div className="flex items-center justify-start space-x-2 w-full max-w-xs">
              <i className={`fa-duotone ${feature.icon} custom-duotone-icon h-6 w-6`}></i>
              <span>{feature.text}</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default KeyFeatures;

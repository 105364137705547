import React from 'react';
import Marquee from './Marquee';
import logo from '../assets/logos/logo_court_anchorpoint-blue.png';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="flex flex-col bg-customBlue text-white py-8">
      {/* Start Marquee */}
      <Marquee />
      {/* End Marquee */}

      {/* Start Footer Links */}
      <div className="px-4 md:px-10 flex flex-col sm:flex-row justify-center sm:justify-between items-center sm:items-end text-center sm:text-left">
        <div className="flex flex-col items-center mx-5 mb-4 lg:mb-0">
          <Link to="/">
            <img src={logo} alt="Logo AnchorPoint" className="w-20 my-2" />
          </Link>
          <Link to="/" className="text-white no-underline">AnchorPoint</Link>
        </div>
        <div className="mx-5 mb-4 lg:mb-0">
          <ul className="list-none p-0">
            <li className="mb-2">
              <Link to="/#contact_address" className="text-white no-underline hover:underline">Contact us</Link>
            </li>
            <li className="mb-2">
              <a href="https://www.linkedin.com/company/anchorpoint-world" target="_blank" rel="noopener noreferrer" className="text-white no-underline hover:underline flex items-center">
                <span>LinkedIn</span>
                <i className="fab fa-linkedin pl-2 leading-4"></i>
                <span className="sr-only">LinkedIn - AnchorPoint</span>
              </a>
            </li>
          </ul>
        </div>
        <div className="mx-5 mb-4 lg:mb-0">
          <ul className="list-none p-0">
            <li className="mb-2">
              <Link to="/legal-mentions.html" className="text-white no-underline hover:underline">Legal mentions</Link>
            </li>
            <li className="mb-2">
              <Link to="/terms-of-sales.html" className="text-white no-underline hover:underline">Terms of sales</Link>
            </li>
             {/* <li>
              <Link to="/sitemap" className="text-white no-underline hover:underline">Sitemap</Link>
            </li> */}
          </ul>
        </div>
      </div>
      {/* End Footer Links */}
    </footer>
  );
};

export default Footer;

import React from 'react';
import { Link } from 'react-router-dom';

const Navigation = ({ isMobile }) => (
  <nav className={`flex ${isMobile ? 'flex-col space-y-4 mt-4' : 'space-x-6'} ${isMobile ? 'md:hidden' : 'hidden md:flex'}`}>
    <Link to="/#about" className="text-lg font-medium text-gray-50 hover:text-gray-200">About</Link>
    <Link to="/#contact" className="text-lg font-medium text-gray-50 hover:text-gray-200">Contact Us</Link>
    <a href="https://www.linkedin.com/company/anchorpoint-world" target="_blank" rel="noopener noreferrer" className={`flex items-center ${isMobile ? 'text-lg' : ''} text-gray-50 hover:text-gray-200`}>
      <i className="fab fa-linkedin h-6 w-6 pr-2"></i>
      <span className="sr-only">LinkedIn - AnchorPoint</span>
      LinkedIn
    </a>
  </nav>
);

export default Navigation;

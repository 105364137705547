import React, { useEffect } from 'react';
import Hero from '../components/Hero'
import { removeRecaptcha, toggleRecaptchaBadge } from "../Common";

const TermsOfSales = () => {
  useEffect(() => {
    toggleRecaptchaBadge(false);
    removeRecaptcha();
  }, []);

  return (
    <div className="bg-customGray">
      <main>      
        <Hero title="AnchorPoint" subtitle="Terms of Sales" />
        {/* Start Section ToS */}
        <section id="legal-mentions" className="mb-16 p-8 animate-fadeInUp scroll-offset">
          <div className="container mx-auto">
            <p className="text-md md:text-lg text-customBlue mb-4">
            Under construction.
            </p>
          </div>
        </section>
        {/* End Section ToS */}
      </main>
    </div>

  );
};

export default TermsOfSales;

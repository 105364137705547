import React from 'react';
import { Link } from 'react-router-dom';

const Card = ({ card }) => (
  <div className="w-full p-4 flex">
    <Link to={card.link} state={{ item: card.name }} className="no-underline text-inherit rounded-lg overflow-hidden flex flex-col w-full">
      <div className="relative">
        <img
          src={card.imgSrc}
          alt={card.title}
          className="w-full h-24 md:h-32 object-cover rounded-t-lg"
        />
        <div
          className="absolute top-0 left-0 w-full h-full"
          style={{
            backgroundColor: '#0E2841',
            mixBlendMode: 'multiply',
            opacity: 0.6
          }}
        />
        <div className="absolute top-0 left-0 bg-white bg-opacity-70 p-4 rounded-br-lg">
          <img src={card.logoSrc} alt={`Logo ${card.title}`} className="h-4 object-contain" />
        </div>
      </div>
      <div className="p-4 flex-grow flex flex-col bg-customGray bg-opacity-90">
        <h4 className="text:md md:text-xl text-customBlue">{card.title}</h4>
        <p className="text-sm text-gray-800 flex-grow">{card.description}</p>
      </div>
    </Link>
  </div>
);

export default Card;


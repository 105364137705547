import React from 'react';
import KeyFeatures from './KeyFeatures';

const About = () => {
  return (
    <section id="about" className="p-8 pb-16 bg-customGray animate-fadeInUp scroll-offset">
      <div className="container mx-auto">
        <div className="mb-12 text-center">
          <h3 className="text-3xl font-bold text-customBlue mb-6">About AnchorPoint</h3>
          <p className="text-xl text-gray-700">Your Central Resource for the Ports & Maritime Industry</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 px-4">
          {/* Start col1 text */}
          <div className="md:col-span-1">
            <h4 className="text-center md:text-left text-2xl font-semibold text-customBlue mb-4">Our Mission</h4>
            <p className="text-lg text-gray-800 mb-6">
              AnchorPoint is a web-based platform for the Ports & Maritime Industry Ecosystem that will be an essential central resource for all P&MI Professionals at all levels across the globe.
            </p>
            <p className="text-lg text-gray-800 mb-6">
              This AI powered platform will provide easily accessible Knowledge, Analytics and Benchmarking, Training, a Digital Marketplace, Human Resources, a Tendering system for Equipment and Projects, Access to Funding Incubation and Investors as well as a Collaborative Space for Innovation and Connectivity.
            </p>
            <p className="text-lg text-gray-800">
              AnchorPoint will <span className="font-bold">accelerate innovation, promote Sustainability, facilitate connectivity and drive efficiency</span> across the Ports & Maritime Industry.
            </p>
          </div>
          {/* End col1 text */}
          {/* Start col2 key features */}
          <div className="md:col-span-1">
            <KeyFeatures />
          </div>
          {/* End col2 key features */}
        </div>
      </div>
    </section>
  );
};

export default About;

import React, { useEffect, useState } from 'react';
import logoPortPulse from "../assets/logos/logo_portpulse-white.png";
import logoPortNexus from "../assets/logos/logo_portnexus-white.png";
import logoPortAcademy from "../assets/logos/logo_portacademy-white.png";
import logoPortCloud from "../assets/logos/logo_portcloud-white.png";
import logoPortTalent from "../assets/logos/logo_porttalent-white.png";
import logoPortVentures from "../assets/logos/logo_portventures-white.png";
import logoPortAnalytics from "../assets/logos/logo_portanalytics-white.png";
import logoPortSurveys from "../assets/logos/logo_portsurveys-white.png";
import logoPortTenders from "../assets/logos/logo_porttenders-white.png";
import logoPortEvents from "../assets/logos/logo_portevents-white.png";
import logoPortGames from "../assets/logos/logo_portgames-white.png";
import logoPortMaps from "../assets/logos/logo_portmaps-white.png";

import logoPulse from "../assets/logos/round_pulse.png";
import logoNexus from "../assets/logos/round_nexus.png";
import logoAcademy from "../assets/logos/round_academy.png";
import logoCloud from "../assets/logos/round_cloud.png";
import logoTalent from "../assets/logos/round_talent.png";
import logoVentures from "../assets/logos/round_ventures.png";
import logoAnalytics from "../assets/logos/round_analytics.png";
import logoSurveys from "../assets/logos/round_surveys.png";
import logoTenders from "../assets/logos/round_tenders.png";
import logoEvents from "../assets/logos/round_events.png";
import logoGames from "../assets/logos/round_games.png";
import logoMaps from "../assets/logos/round_maps.png";

const Who = () => {
  const [rows, setRows] = useState([]);
  useEffect(() => {
    setRows([
      {
        leftText: "Stay Informed with Industry News",
        logo: logoPortPulse,
        rightText: "Publish Articles to a Targeted Audience",
        mobileLogo: logoPulse,
      },
      {
        leftText: "Expand Your Knowledge with Expert Resources",
        logo: logoPortNexus,
        rightText: "Share Best Practices and Research",
        mobileLogo: logoNexus,
      },
      {
        leftText: "Access Top Training Programs",
        logo: logoPortAcademy,
        rightText: "Offer Training to a Global Network",
        mobileLogo: logoAcademy,
      },
      {
        leftText: "Find Innovative Digital Solutions",
        logo: logoPortCloud,
        rightText: "Promote and Sell Digital Solutions",
        mobileLogo: logoCloud,
      },
      {
        leftText: "Recruit Top Talent & Discover Global Job Opportunities",
        logo: logoPortTalent,
        rightText: "Advertise Consulting Services",
        mobileLogo: logoTalent,
      },
      {
        leftText: "Secure Funding for Projects",
        logo: logoPortVentures,
        rightText: "Publish Statistics and Trend Analyses",
        mobileLogo: logoVentures,
      },
      {
        leftText: "Analyze Latest Trends and Statistics",
        logo: logoPortAnalytics,
        rightText: "Conduct and Access Satisfaction Surveys",
        mobileLogo: logoAnalytics,
      },
      {
        leftText: "Launch and Review Satisfaction Surveys",
        logo: logoPortSurveys,
        rightText: "Showcase Products and Respond to Tenders",
        mobileLogo: logoSurveys,
      },
      {
        leftText: "Source and Purchase Equipment",
        logo: logoPortTenders,
        rightText: "Advertise Events and Leverage Our Sales Platform",
        mobileLogo: logoTenders,
      },
      {
        leftText: "Plan and Attend Global Events",
        logo: logoPortEvents,
        rightText: "Offer Crisis Management Services",
        mobileLogo: logoEvents,
      },
      {
        leftText: "Prepare for Crises with Advanced Techniques",
        logo: logoPortGames,
        rightText: "Offer Crisis Management Services",
        mobileLogo: logoGames,
      },
      {
        leftText: "Map Your Logistics Environment",
        logo: logoPortMaps,
        rightText: "Understand Logistics Networks and Identify Opportunities",
        mobileLogo: logoMaps,
      },
    ]);
  }, []);

  return (
    <>
      {/* Desktop View */}
      <div className="flex-col gap-1 my-20 text-left max-w-[1000px] mx-auto hidden md:flex">
        {/* Headers */}
        <div className="flex flex-row justify-between align-middle mb-10">
          <div className="font-bold text-xl text-gray-200 text-right flex-1">
            You are a Port and Maritime <br />
            Professional looking to:
          </div>
          <div className="flex-logo"></div>
          <div className="font-bold text-xl text-gray-200 text-left flex-1">
            You are a Service Provider, Academic Organization, or Investor
            looking to:
          </div>
        </div>

        {/* Rows */}
        {rows.map((row, index) => (
          <div className="custom-row flex-row" key={index}>
            <div className="flex-1 text-gray-50 text-right">{row.leftText}</div>
            <div className="flex-logo flex justify-center items-center">
              <img src={row.logo} alt={row.leftText} className="h-12" />
            </div>
            <div className="flex-1 text-gray-50">{row.rightText}</div>
          </div>
        ))}
      </div>

      {/* Mobile View */}
      <div className="md:hidden space-y-4 mb-10 mx-5">
        <h4 className="font-bold text-lg text-gray-200 mt-10">
          You are a Port & Maritime Professional looking to:
        </h4>
        {rows.map((row, index) => (
          <div
            className="flex items-center justify-between space-x-2"
            key={index}
          >
            <span className="text-gray-50 w-2/3">{row.leftText}</span>
            <img src={row.mobileLogo} alt={row.leftText} className="h-12 w-12" />
          </div>
        ))}
        <h4 className="font-bold text-lg text-gray-200 pt-6">
          You are a Solution/Service Provider, Academic Organization, or
          Investor looking to:
        </h4>
        {rows.map((row, index) => (
          <div
            className="flex items-center justify-between space-x-2"
            key={index}
          >
            <img src={row.mobileLogo} alt={row.rightText} className="h-12 w-12" />
            <span className="text-gray-50 w-2/3">{row.rightText}</span>
          </div>
        ))}
      </div>
    </>
  );
};

export default Who;
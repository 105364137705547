import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import carouselPulse from '../assets/images/carousel_pulse.webp';
import carouselMaps from '../assets/images/carousel_maps.webp';
import carouselAnalytics from '../assets/images/carousel_analytics.webp';
import carouselSurveys from '../assets/images/carousel_surveys.webp';
import carouselAnchorpoint from '../assets/images/carousel_anchorpoint.webp';

const carouselData = [
  {
    image: carouselPulse,
    text: 'Discover PortPulse, your leading source for maritime and port industry news',
    url: 'https://portpulse.anchorpoint.world'
  },
  {
    image: carouselMaps,
    text: 'Explore PortMaps, our platform for personalized maritime and port maps',
    url: 'https://portmaps.anchorpoint.world'
  },
  {
    image: carouselAnalytics,
    text: 'PortAnalytics, your go-to for comprehensive data analysis and insightful charts',
    url: 'https://portanalytics.anchorpoint.world'
  },
  {
    image: carouselSurveys,
    text: 'On PortSurveys, we provide detailed surveys for all your needs',
    url: 'https://portsurveys.anchorpoint.world'
  },
  {
    image: carouselAnchorpoint,
    text: 'Interested by other AnchorPoint services? Join the waiting list',
    url: '/waiting-list.html'
  }
];

const Carousel = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselData.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + carouselData.length) % carouselData.length);
  };

  return (
    <section className="carousel relative overflow-hidden w-full animate-fadeInUp">
      <div className="carousel-inner relative w-full h-44 md:h-64">
        {carouselData.map((item, index) => (
          <div
            key={index}
            className={`carousel-item ${index === currentIndex ? 'active' : ''}`}
            style={{ backgroundImage: `url(${item.image})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
          >
            <div className="carousel-caption text-lg md:text-4xl md:rounded-tl-lg text-white">
              {item.url.startsWith('http') ? (
                <a href={item.url} className="text-white">
                  {item.text}
                </a>
              ) : (
                <Link to={item.url} className="text-white">
                  {item.text}
                </Link>
              )}
            </div>
          </div>
        ))}
      </div>
      <button
        title="previous_btn"
        type="button"
        className="prev absolute top-1/3 md:top-1/2 left-4 transform -translate-y-1/2 bg-customBlue bg-opacity-60 text-gray-50 p-2 rounded-full"
        onClick={prevSlide}
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
        </svg>
      </button>
      <button
        title="next_btn"
        type="button"
        className="next absolute top-1/3 md:top-1/2 right-4 transform -translate-y-1/2 bg-customBlue bg-opacity-60 text-gray-50 p-2 rounded-full"
        onClick={nextSlide}
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
        </svg>
      </button>
    </section>
  );
};

export default Carousel;
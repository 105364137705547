import React from 'react';

const Hero = ({ title, subtitle }) => {
  return (
    <section id="hero" className="relative w-full h-96 bg-cover bg-center -mt-16 hero animate-fadeInUp">
      <div className="absolute bottom-0 left-0 p-8 bg-customBlue bg-opacity-50 text-gray-50 rounded-tr-lg">
        <h1 className="text-center md:text-left text-4xl font-bold">{title}</h1>
        <h2 className="text-center md:text-left text-xl md:text-2xl">{subtitle}</h2>
      </div>
    </section>
  );
};

export default Hero;

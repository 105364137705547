import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  gRecaptchaSiteKey,
  loadRecaptcha,
  toggleRecaptchaBadge,
} from "../Common";
// https://stackoverflow.com/a/60627963/10372580
// import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    company: "",
    jobTitle: "",
    email: "",
    modules: [],
    comments: "",
  });

  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [recaptchaError, setRecaptchaError] = useState(false);

  // https://stackoverflow.com/a/75914775/10372580
  const location = useLocation();
  const [showRecaptcha, setShowRecaptcha] = useState(false);

  useEffect(() => {
    toggleRecaptchaBadge(true);
    return () => toggleRecaptchaBadge(false);
  }, []);

  useEffect(() => {
    setShowRecaptcha(location.pathname.includes("waiting-list"));
    const script = loadRecaptcha();
    document.body.appendChild(script);
  }, [location.pathname]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prevData) => {
        if (checked) {
          return { ...prevData, modules: [...prevData.modules, value] };
        } else {
          return {
            ...prevData,
            modules: prevData.modules.filter((module) => module !== value),
          };
        }
      });
    } else {
      setFormData({ ...formData, [name]: sanitizeInput(value) });
    }
  };

  const sanitizeInput = (input) => {
    // Remove potentially harmful characters
    return input
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  };

  const siteVerify = () => {
    window.grecaptcha.ready((_) => {
      window.grecaptcha
        .execute(gRecaptchaSiteKey, {
          action: "submit",
        })
        .then((token) => {
          fetch("https://api.anchorpoint.world/recaptcha/siteverify", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              sitekey: gRecaptchaSiteKey,
              token,
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.success) {
                const elemRecaptachaResponse =
                  document.getElementsByClassName("g_recaptcha_response");
                if (elemRecaptachaResponse.length > 0) {
                  elemRecaptachaResponse[0].value = "success";
                }
              } else {
                setRecaptchaError(true);
                console.error("reCAPTCHA verification failed:", data.error);
              }
            })
            .catch((error) => {
              setRecaptchaError(true);
              console.error("Error verifying reCAPTCHA:", error);
            });
        });
    });
  };

  const submitForm = () => {
    // Associate the FormData object with the form element
    const form = document.getElementById("interest-form");
    const formData = new FormData(form);

    // Uncomment: If reverting to process form submission
    // const recaptchaResponseField = document.getElementById("g_recaptcha_response");
    // recaptchaResponseField.value = formData.get("g-recaptcha-response");

    formData.delete("g-recaptcha-response");
    formData.set("submit_button", "submit_button");

    // Uncomment: If reverting to process form submission
    // Cleanup non-supported named fields to avoid form tools error
    // let elemRecaptachaResponse = document.getElementsByClassName("g-recaptcha-response");
    // if (elemRecaptachaResponse.length > 0) {
    //   elemRecaptachaResponse[0].remove();
    // }

    const postPayload = new URLSearchParams(formData);

    // fetch("https://api.anchorpoint.world/forms/wait-list", {});
    // fetch("https://apps.anchorpoint.world/formtools/process.php", {});

    fetch("https://api.anchorpoint.world/forms/wait-list", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "*/*",
      },
      body: postPayload,
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data.success) {
          setSubmissionStatus("success");
          // Submit the form if needed
          // form.submit();
        } else {
          setErrorMessage("Error processing the form submission.");
          setSubmissionStatus("error");
        }
      })
      .catch((error) => console.error(error));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmissionStatus(null);
    setErrorMessage(null);

    // Basic email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      setErrorMessage("Please enter a valid email address.");
      setSubmissionStatus("error");
      return;
    }

    siteVerify();
    if (recaptchaError) {
      setErrorMessage("Error verifying reCAPTCHA.");
      setSubmissionStatus("error");
      return;
    }

    submitForm();
  };

  // action="https://apps.anchorpoint.world/formtools/process.php"
  return (
    <div className="flex justify-center">
      <form
        className="w-full"
        id="interest-form"
        onSubmit={handleSubmit}
        method="POST"
      >
        <div>
          {/* Uncomment as needed to revert or initialize with process.php*/}
          {/* <input type="hidden" name="form_tools_initialize_form" value="1" /> */}
          {/* <input type="hidden" name="form_tools_form_id" value="1" /> */}
          {/* <input type="hidden" name="form_tools_form_id" value="2" /> */}
          <input
            type="hidden"
            id="g_recaptcha_response"
            name="g_recaptcha_response"
          />
        </div>
        <fieldset>
          <legend className="text-2xl font-bold mb-4 text-customBlue">
            Contact Us
          </legend>
          <div className="mb-4">
            <label
              htmlFor="fullName"
              className="block text-gray-700 font-bold mb-2"
            >
              Full Name <span className="text-customRed">*</span>
            </label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              required
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-customBlueTalent"
              aria-required="true"
              value={formData.fullName}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="company"
              className="block text-gray-700 font-bold mb-2"
            >
              Your Company Name <span className="text-customRed">*</span>
            </label>
            <input
              type="text"
              id="company"
              name="company"
              required
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-customBlueTalent"
              aria-required="true"
              value={formData.company}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="jobTitle"
              className="block text-gray-700 font-bold mb-2"
            >
              Job Title <span className="text-customRed">*</span>
            </label>
            <input
              type="text"
              id="jobTitle"
              name="jobTitle"
              required
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-customBlueTalent"
              aria-required="true"
              value={formData.jobTitle}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-gray-700 font-bold mb-2"
            >
              Email <span className="text-customRed">*</span>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              required
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-customBlueTalent"
              aria-required="true"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="modules"
              className="block text-gray-700 font-bold mb-2"
            >
              Which modules are you interested in?{" "}
              <span className="text-customRed">*</span>
            </label>
            <div id="modules" className="space-y-2">
              {[
                "PortAcademy",
                "PortAnalytics",
                "PortCloud",
                "PortEvents",
                "PortGames",
                "PortMaps",
                "PortNexus",
                "PortPulse",
                "PortSurveys",
                "PortTalent",
                "PortVentures",
                "PortTenders",
              ].map((module) => (
                <div key={module}>
                  <input
                    type="checkbox"
                    id={module.toLowerCase()}
                    name="modules[]"
                    value={module}
                    className="mr-2"
                    checked={formData.modules.includes(module)}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor={module.toLowerCase()}
                    className="text-gray-700"
                  >
                    {module}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="mb-4">
            <label
              htmlFor="comments"
              className="block text-gray-700 font-bold mb-2"
            >
              Any comment or question?
            </label>
            <textarea
              id="comments"
              name="comments"
              rows="4"
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
              value={formData.comments}
              onChange={handleChange}
            ></textarea>
          </div>
          {showRecaptcha && (
            <div
              className="g-recaptcha"
              data-sitekey={gRecaptchaSiteKey}
              data-size="invisible"
            ></div>
          )}
          {submissionStatus === "success" && (
            <div className="text-green-500 mb-4">
              Your message has been sent successfully!
            </div>
          )}
          {submissionStatus === "error" && (
            <div className="text-red-500 mb-4">{errorMessage}</div>
          )}
          <div>
            <button
              type="submit"
              name="submit_button"
              id="submit_button"
              disabled={submissionStatus === "success"}
              className="w-full bg-customBlue text-white font-bold py-2 px-4 rounded-md hover:bg-customBlueTalent focus:outline-none focus:ring focus:border-customBlueTalent"
            >
              Submit
            </button>
          </div>
        </fieldset>
      </form>
    </div>
  );
};

export default ContactForm;
